body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar {
  background: rgb(1, 43, 81);
  background: linear-gradient(
    90deg,
    rgba(1, 43, 81, 1) 0%,
    rgba(1, 98, 157, 1) 53%
  );
}

.nav-link {
  color: #fff !important;
  margin-left: 35px;
  font-size: 20px;
}
.navbar-text-button {
  color: #fff !important;
  text-decoration: none;
  border-radius: 6px;
  border: 2px solid #fff;
  padding: 11px;
  /* font-size: 15px; */
}
.navbar-toggler {
  background: #fff !important;
}
.hero-heading {
  font-size: 54px;
}
.hero-para {
  font-size: 24px;
  padding: 35px 0px 35px 0px;
  text-align: start;
}
.international-edu {
  color: #fff !important;
  background: #0e76be !important;
  padding: 11px;
  width: 100%;
}
.download {
  border: 1px solid #fff !important;
  color: #fff !important;
  /* background: #0e76be !important; */
  padding: 11px;
  /* width: 100%; */
}
.international-emply {
  background-color: #eb0288 !important;
  color: #fff !important;
  padding: 11px;
  width: 100%;
}
a {
  text-decoration: none !important;
}
.register-work-partner {
  border: 2px solid #0362bb !important;
  color: #fff !important;
  background: #0e76be !important;
}
.gap {
  margin-top: 105px;
  margin-bottom: 50px;
}
.common-heading {
  font-size: 48px;
}
.common-para {
  font-size: 16px;
  color: #263238;
}
.card-heading {
  font-size: 30px;
}
.test-offering {
  background: #f1f7fb;
  /* font-size: 40px; */
}
.font-size-40 {
  font-size: 40px;
}
.small-heading {
  font-size: 18px;
  font-weight: bold;
}
.divider {
  background-color: #64748b;
  width: 100%;
  height: 1px;
}
.practice-tests {
  color: #eb0288;
  border: 1px solid #eb0288;
  border-radius: 55px;
  padding: 8px;
  font-size: 16px;
}
.success-learner-heading {
  font-size: 20px;
}
.success-learner-designation {
  font-size: 14px;
}
.global-opportunities {
  background-image: url("./assests/opportunity.jpg");
  background-repeat: no-repeat;
  padding: 40px;
}
.why-choose-skillstrainer {
  background-image: url("./assests//whyChoose.png");
  background-repeat: no-repeat;
  height: 607px;
  background-size: cover;
  /* display: flex; */
  /* flex-direction: column; /* This makes the container a column flexbox */
  /* justify-content: flex-end; */
}
.background-color {
  background-color: #f1f7fb !important;
}
/* MEDIA QUERIES */
@media (max-width: 991.98px) {
  .nav-link {
    margin-left: 0px;
  }
  .navbar-nav {
    text-align: left;
  }
  .navbar-collapse {
    text-align: left;
  }
  .download-brochure {
    margin-top: 24px;
  }
}

@media (max-width: 767.98px) {
  .hero-heading {
    font-size: 34px;
  }
  .hero-para {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .hero-heading {
    font-size: 34px;
  }
}

@media (min-width: 1400px) {
  .custom-container {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.img-bg {
  margin-top: 3%;
  background-image: url("./assests/map.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-bg {
  margin-top: 3%;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* logo */
.brands_list {
  margin: 0;
  font-size: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.brand_item {
  padding: 6px;
  /* background: #80808008; */
  text-align: center;
  position: relative;
  width: 12.33%;
  vertical-align: middle;
  box-sizing: border-box;
  text-transform: uppercase;
  margin: 6px;
  /* border: 1px solid rgba(213, 217, 222, 0.6); */
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
  -webkit-transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);
  -moz-transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);
}

img.brand_logo {
  max-height: 8rem;
  width: 85%;
  max-width: 100%;
}
h2.brand_logo {
  font-weight: 300;
  line-height: 1.1;
  font-size: 3rem;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.brands_list_portal {
  margin: 0px 0px 0px -13px;
  font-size: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  /* justify-content: center; */
}

.brand_item_portal {
  padding: 16px;
  /* background: linear-gradient(157.09deg, #77a762 14.85%, #b7ff98 112.89%); */
  border-radius: 11px;
  width: 22.33%;
  margin: 8px;
  border: 5px solid rgba(213, 217, 222, 0.6);
  display: inline-flex;
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);
  height: 137.64px;
}

img.brand_logo_portal {
  max-height: 8rem;
  width: auto;
  max-width: 100%;
}
h2.brand_logo_portal {
  font-weight: 300;
  line-height: 1.1;
  font-size: 3rem;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.brands_list_portal_text {
  margin: -20px 0px 0px -13px;
  padding: 0;
}

.brand_item_portal_text {
  padding: 0px 11px;
  border-radius: 11px;
  width: 20.33%;
  margin: 16px;
  display: inline-flex;
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1);
  height: 137.64px;
}

img.brand_logo_portal_text {
  max-height: 8rem;
  width: auto;
  max-width: 100%;
}
